<template>
  <div class="roleBox fatherHeight">
    <div class="contBox fatherHeight">
      <div class="role-top flex-between">
        <el-form
          :inline="true"
          :model="tableSearch"
          ref="searchForm"
          class="demo-form-inline w-144"
        >
        <el-form-item label="名称：" prop="name">
          <el-input v-model="tableSearch.name" placeholder="请输入" />
        </el-form-item>
        </el-form>
        <div>
          <el-button @click="handleSearch" class="color-main" type="primary">
            <i class="fa fa-search"></i>查询
          </el-button>
          <el-button @click="addRole" class="color-green" type="primary">
            <i class="fa fa-plus-circle"></i>新增
          </el-button>
        </div>
      </div>
      <div class="table" ref="tableBoxRef">
        <el-table
          v-loading="dataListLoading"
          :data="tableData"
          class="table-ellipsis"
          v-if="istable"
          size="small"
          border
          :max-height="tableHei"
        >
          <el-table-column prop="name" label="名称" />
          <el-table-column label="排序" prop="sort">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="140">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="modParient(scope.row)"
              >
                <i class="txIons modify"></i>
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="delParient(scope.row)"
              >
                <i class="txIons del"></i>
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="downloadfile(scope.row.path)"
              >
                <i class="txIons lower"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            :currentPage="pageData.currentPage"
            :page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.totalSum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Add ref="addRef" @refreshDataList="getDataList"></Add>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import Add from './add.vue'

const tableData = ref([])
const istable = ref(true)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableSearch = reactive({
  is_use: 1,
})
const tableBoxRef = ref(null)
const tableHei = ref(0)
const dataListLoading = ref(false)

onMounted(() => {
  istable.value = false
  tableHei.value = tableBoxRef.value.clientHeight - 40
  nextTick(() => {
    istable.value = true
  })
  getDataList()
})
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const getDataList = async () => {
  tableSearch.page = pageData.currentPage
  tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/file/list_file', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.list
    pageData.totalSum = res.data.total
  }
}
const addRef = ref(null)
const addRole = () => {
  addRef.value.initData()
}
const modParient = (item) => {
  addRef.value.initData(JSON.parse(JSON.stringify(item)))
}
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/file/del_file', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}
const downloadfile = (url)=>{
  let urls = 'http://tdms_api.bjqiju.com/storage/'+ url
  window.open(urls)
}
</script>

<style scoped lang="scss">
.role-center {
  margin-top: 8px;
  .el-alert {
    width: auto;
    color: #3166ae;
    background: rgba(49, 102, 174, 0.1);
  }
}
.table {
  height: calc(100% - 80px);
}
</style>
